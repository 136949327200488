import "./rjv2025Page.css";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

function Rjv2025Page() {
  function RJVUrl() {
    window.location.href = "https://rjvsaviese.ch"; // Remplacez par l'URL cible
  }
  return (
    <div className="animate-fade-up  px-6 md:py-20 ">
      <Helmet>
        <title>RJV 2025 - Jeunesse de Savièse</title>
        <meta
          name="description"
          content="La description du rassemblement des jeunesses valaisannes, dont Savièse sera les organisateurs pour 2025."
        />
        <meta
          name="keywords"
          content="jeunesse, Savièse, Valais, RJV, RJV 2025, Rassemblement des jeunesses valaisannes, RJV à Savièse"
        />
      </Helmet>
      <div className="grid lg:grid-cols-2 gap-8 max-lg:max-w-2xl mx-auto">
        <div className="text-left ">
          <h2 className="text-4xl font-extrabold mb-6 text-center ">
            Rassemblement des jeunesses valaisannes
          </h2>
          <div className="text-center text-[#666] text-lg ">
            <p className="mb-4 ">
              Suite à sa victoire en 2023 aux RJV de Chalais, la Jeunesse de
              Savièse s'est engagée à organiser les RJV 2025 !
            </p>
            <p className="mb-4 ">
              Ce week-end festif est ouvert à tous, où les jeunesses valaisannes
              s'affronteront à travers divers jeux. Boissons, restauration, DJ
              et concerts seront au rendez-vous.
            </p>
            <p className="">
              Si vous souhaitez nous aider à mener à bien ce projet, que ce soit
              par un <Link to="/dons"> don</Link> ou directement par{" "}
              <a href="mailto:jeunessedesaviese@gmail.com">mail</a> avec
              d'autres idées, nous vous remercions d'avance et espérons vous
              voir nombreux pour les festivités !
            </p>
          </div>
          <div className="xl:grid-cols-3 grid-cols-2 gap-8 mt-12 hidden xl:grid">
            <div className="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                className="fill-green-500 shrink-0"
                viewBox="0 0 24 24"
              >
                <path
                  d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z"
                  data-original="#000000"
                ></path>
              </svg>
              <h6 className="text-base font-semibold ml-4 ">Épreuve</h6>
            </div>
            <div className="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                className="fill-green-500 shrink-0"
                viewBox="0 0 24 24"
              >
                <path
                  d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z"
                  data-original="#000000"
                ></path>
              </svg>
              <h6 className="text-base font-semibold ml-4">Bonne ambiance</h6>
            </div>
            <div className="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                className="fill-green-500 shrink-0"
                viewBox="0 0 24 24"
              >
                <path
                  d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z"
                  data-original="#000000"
                ></path>
              </svg>
              <h6 className="text-base font-semibold ml-4">
                Par les jeunes pour les jeunes
              </h6>
            </div>
            <div className="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                className="fill-green-500 shrink-0"
                viewBox="0 0 24 24"
              >
                <path
                  d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z"
                  data-original="#000000"
                ></path>
              </svg>
              <h6 className="text-base font-semibold ml-4">Artistes au RDV</h6>
            </div>
            <div className="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                className="fill-green-500 shrink-0"
                viewBox="0 0 24 24"
              >
                <path
                  d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z"
                  data-original="#000000"
                ></path>
              </svg>
              <h6 className="text-base font-semibold ml-4">
                Collaboration avec les commerces locaux
              </h6>
            </div>
            <div className="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                className="fill-green-500 shrink-0"
                viewBox="0 0 24 24"
              >
                <path
                  d="M9.707 19.121a.997.997 0 0 1-1.414 0l-5.646-5.647a1.5 1.5 0 0 1 0-2.121l.707-.707a1.5 1.5 0 0 1 2.121 0L9 14.171l9.525-9.525a1.5 1.5 0 0 1 2.121 0l.707.707a1.5 1.5 0 0 1 0 2.121z"
                  data-original="#000000"
                ></path>
              </svg>
              <h6 className="text-base font-semibold ml-4">
                Mise en avant des jeunesses
              </h6>
            </div>
          </div>
        </div>
        <div className="md:h-fit  flex flex-auto justify-center items-center hover:scale-105 transition-transform duration-300 click:scale-0">
          <img
            src={require("../../images/RJV.png")}
            alt="Image du RJV"
            className="rounded-lg object-cover w-5/6 h-5/6 "
          />
        </div>
        <button
          onClick={RJVUrl}
          className="px-6 py-3 mb-3  rounded text-white text-lg  font-medium outline-none border-2 border-[#ff6348] bg-[#ff6348] hover:bg-transparent hover:text-black transition-all duration-300  "
        >
          Vers le site du RJV 2025
        </button>{" "}
      </div>
    </div>
  );
}

export default Rjv2025Page;
